import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service";
import CircularProgress from "@material-ui/core/CircularProgress";

const Login = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();
  const userExists = AuthService.getCurrentUser();
  if (userExists) {
    history.push("/admin/index");
  }

  const handleSignIn = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    let browserSaved = true;
    AuthService.login(user, password, browserSaved)
      .then((res) => {
        sessionStorage.setItem("user", user);
        history.push("/admin/index");
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMessage(err.message === 'Network Error' ? 'Server error!' : err.response.data.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="h1 text-center mb-4" style={{ color: "#0ca6f2" }}>
              <div>Login</div>
            </div>
            {errorMessage && (
              <div className="h4 text-center mb-4" style={{ color: "#ff0000" }}>
                <div>{errorMessage}</div>
              </div>
            )}

            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="textBg">
                      <i className="ni ni-circle-08" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Username"
                    type="text"
                    autoComplete="new-email"
                    className="textBg"
                    onChange={(e) => setUser(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText className="textBg">
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    className="textBg"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  type="submit"
                  onClick={handleSignIn}
                  style={{ backgroundColor: "#0ca6f2", border: "none" }}
                >
                  {isLoading ? (
                    <>
                      Sign In <CircularProgress className="ml-3" size={15} />
                    </>
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="h3"
              href="#pablo"
              style={{ color: "#a7ce39" }}
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <span
              className="h3"
              style={{ cursor: "pointer", color: "#a7ce39" }}
              onClick={(e) => history.push("/auth/register")}
            >
              <small>New User? Register</small>
            </span>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

export default Login;
