import React from "react";
import PropTypes from "prop-types";
import { CardBody } from "reactstrap";

function Table(props) {
  return (
    <CardBody className="py-0">
      <div className="table-responsive my-4">
        <table className={props.tableClasses.table}>
          {props.noHeader ? null : (
            <thead className={props.tableClasses.thead}>
              <tr className={props.tableClasses.tr}>
                {props.columns.map((item) => {
                  return (
                    <th
                      key={item.id}
                      className={item.className}
                      onClick={item.onHeadingClick ? item.onHeadingClick : null}
                      style={{ fontSize: "16px" }}
                    >
                      {item.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody className={props.tableClasses.tbody}>
            {props.values.map((record, index) => (
              <tr
                key={index}
                className={
                  props.rowClassName
                    ? props.rowClassName
                    : record.changed
                    ? "font-weight-bolder bg-threshold text-dark"
                    : ""
                }
                onClick={() =>
                  props.onRowClick ? props.onRowClick(record) : null
                }
              >
                {props.columns.map((col) => (
                  <td
                    key={col.id}
                    className={col.className}
                    onClick={() =>
                      col.onClick && !props.onRowClick
                        ? col.onClick(record)
                        : null
                    }
                  >
                    {col.render ? col.render(record, index) : record[col.id]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </CardBody>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  values: PropTypes.array,
  rowClassName: PropTypes.string,
  onRowClick: PropTypes.func,
  tableClasses: PropTypes.object,
  noHeader: PropTypes.bool,
};

Table.defaultProps = {
  columns: [
    {
      id: "test",
      title: "test",
      className: "",
      onClick: null,
      render: null,
    },
  ],
  values: [
    {
      test: "test",
    },
  ],
  rowClassName: "",
  onRowClick: null,
  tableClasses: {
    table: "",
    thead: "",
    tr: "",
  },
  noHeader: false,
};

export default Table;
