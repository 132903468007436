import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { baseUrl, baseUrlManagement } from "constants/url";
import axios from "axios";
import authHeader from "services/auth-header";
import { Spinner } from "reactstrap";
import MachineDataCard from "./MachineDataCard";
import { Card, CardBody, Col } from "reactstrap";
import MachinesSpeedGraph from "./MachinesSpeedGraph";
import Filters from "views/shared/Filters";
import ColorLegend from "components/ColorLegend";

const MachinesListing = ({ clientId }) => {
  const history = useHistory();
  // const today = new Date();
  // const yesterday = new Date(today);
  // // yesterday.setDate(yesterday.getDate() - 7);
  // yesterday.setHours(yesterday.getHours() - 8);

  // const [filtersData, setFiltersData] = useState({
  //   // start_date: "2023-01-13T11:35:04.00Z",
  //   start_date: yesterday.toISOString(),
  //   // end_date: "2023-01-13T11:35:04.30Z",
  //   end_date: today.toISOString(),
  // });
  const [isLoading, setIsLoading] = useState(true);
  const [machinesData, setMachinesData] = useState([]);

  const timerRef = useRef(null);

  const getData = (key, clientMachines) => {
    const today = new Date();
    const yesterday = new Date(today);
    // yesterday.setDate(yesterday.getDate() - 7);
    yesterday.setHours(yesterday.getHours() - 8);
    axios
      .post(
        `${baseUrl}/api/admin/machinesData`,
        {
          start_date: today.toISOString(),
          end_date: today.toISOString(),
          clientId,
          initialReq: key,
          machinesIdArray: clientMachines,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setMachinesData(res.data.dataArray);
        setIsLoading(false);
        timerRef.current = setTimeout(() => {
          getData("no", clientMachines);
        }, 30000);
      })
      .catch((err) => {
        setMachinesData([]);
        setIsLoading(false);
        timerRef.current = setTimeout(() => {
          getData("no", clientMachines);
        }, 30000);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    let clientMachines = [];
    axios
      .get(`${baseUrl}/api/admin/client_machines/${clientId}`, {
        headers: authHeader(),
      })
      .then((res) => {
        res.data.machines.forEach((item, index) => {
          clientMachines.push({
            machineId: item.machineId,
            img: item.machineType && item.machineType.imagePath,
            line: item.lineName
          });
        });
        getData("yes", clientMachines);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [clientId]);
  // }, [eIndexName, filtersData]);

  // const handleApplyFilters = (filterData) => {
  //   setFiltersData({ ...filterData });
  // };

  if (isLoading) {
    return (
      // <Grid
      //   container
      //   direction="column"
      //   justifyContent="center"
      //   alignItems="center"
      // >
      //   <Grid item xs={12} className="p-0" style={{ width: "90vw" }}>
      //     <Card className="shadow my-4">
      //       <CardBody className="rounded px-5 py-4">
      //         <Filters
      //           filtersData={filtersData}
      //           handleApplyFilters={handleApplyFilters}
      //         />
      //       </CardBody>
      //     </Card>
      //   </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "65vh" }}
        >
          <Spinner />
        </Grid>
      // </Grid>
    );
  } else if (machinesData.length > 0) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {/* <ColorLegend /> */}
        {/* <Grid item xs={12} className="p-0" style={{ width: "90vw" }}>
          <Card className="shadow my-4">
            <CardBody className="rounded px-5 py-4">
              <Filters
                filtersData={filtersData}
                handleApplyFilters={handleApplyFilters}
              />
            </CardBody>
          </Card>
        </Grid> */}
        {machinesData.map((machine, idx) => {
          return (
            <Grid
              item
              xs={12}
              className="btn btn-custom-hover-machine neumorphic p-0 my-3"
              style={{ width: "90vw" }}
              key={idx}
              onClick={() => {
                // if (machine.Status === "OFFLINE") {
                //   return;
                // }
                history.push(
                  `/admin/productionData/${clientId}/${machine.machineId}`
                );
              }}
            >
              <MachineDataCard data={machine} />
            </Grid>
          );
        })}
        <Col xs="12" className="my-4" style={{ width: "92vw" }}>
          <Card className="card-stats" style={{ height: "100%" }}>
            <CardBody className="p-0">
              <MachinesSpeedGraph title={"Speed/Min"} allData={machinesData} />
            </CardBody>
          </Card>
        </Col>
      </Grid>
    );
  } else {
    return (
      // <Grid
      //   container
      //   direction="column"
      //   justifyContent="center"
      //   alignItems="center"
      // >
      //   <Grid item xs={12} className="p-0" style={{ width: "90vw" }}>
      //     <Card className="shadow my-4">
      //       <CardBody className="rounded px-5 py-4">
      //         <Filters
      //           filtersData={filtersData}
      //           handleApplyFilters={handleApplyFilters}
      //         />
      //       </CardBody>
      //     </Card>
      //   </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "65vh" }}
          className="h1 text-white"
        >
          No Machines Found
        </Grid>
      // </Grid>
    );
  }
};

export default MachinesListing;
