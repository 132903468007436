import React from "react";
import { Modal, ModalBody } from "reactstrap";
import TableAuditTrailData from "./TableAuditTrailData";

function AuditDataModal({ isOpen, toggle, clientId, machineId, filter }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdropClassName="modal-backdrop-opacity"
      contentClassName="modal-bg px-4"
      className="modal-width"
    >
      <ModalBody className="p-3">
        <TableAuditTrailData
          filter={filter}
          clientId={clientId}
          title={"Audit Trail"}
          machineId={machineId}
        />
      </ModalBody>
    </Modal>
  );
}

export default AuditDataModal;
