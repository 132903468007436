import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row } from "reactstrap";
import Plot from "react-plotly.js";

const backgroundColor = [
  "#3B2244",
  "#CD966B",
  "#004369",
  "#DB1F48",
  "#130170",
  "#b8fa89", // this is color for total average
];

function MachinesSpeedGraph({ title, allData }) {
  const [chartData, setChartData] = useState([]);
  const [labelFormat, setFormat] = useState(" %H:%M \n %d %B (%a)");
  const [chartLayout, setChartLayout] = useState({
    showlegend: true,
    // responsive: true,
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title: "Lines PPM" },
    xaxis: {
      tickformat: labelFormat,
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
    datarevision: 0,
  });

  useEffect(() => {
    let chartDataArray = chartData;

    allData.forEach((item, idx) => {
      let machineExist = chartDataArray.findIndex(
        (obj) => obj.name === item.Machine_ID
      );
      if (item.Status !== 'OFFLINE') {
        if (machineExist > -1) {
          let timeExist = chartDataArray[machineExist].x.findIndex(
            (value) => value === item.timeStamp
          );
          if (timeExist === -1) {
            chartDataArray[machineExist].x.push(item.timeStamp);
            chartDataArray[machineExist].y.push(item.Speed);
            const newLayout = Object.assign({}, chartLayout);
            newLayout.datarevision++;
            setChartLayout(newLayout);
          }
        } else {
          chartDataArray.push({
            name: item.Machine_ID,
            type: "line",
            borderColor: backgroundColor[idx],
            backgroundColor: backgroundColor[idx],
            //borderWidth: 2,
            x: item.initialReqKey === "yes" ? item.graphData.x : [item.timeStamp],
            y: item.initialReqKey === "yes" ? item.graphData.y : [item.Speed],
          });
          const newLayout = Object.assign({}, chartLayout);
          newLayout.datarevision++;
          setChartLayout(newLayout);
        }
      }
    });

    setChartData(chartDataArray);
    setFormat(" %H:%M \n %d %B (%a)");
    return () => {
      setChartData([]);
      const newLayout = Object.assign({}, chartLayout);
      newLayout.datarevision = 0;
      setChartLayout(newLayout);
    };
  }, [allData]);

  const config = {
    displayModeBar: false,
  };

  const formattedChartData = chartData;

  return (
    formattedChartData.length > 0 && (
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h2 className="mb-0 text-muted">{title}</h2>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart" style={{ height: "auto" }}>
            <div class="chart-container-bar">
              {chartData && (
                <Plot
                  data={formattedChartData}
                  className="w-100 h-100"
                  useResizeHandler
                  layout={chartLayout}
                  config={config}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    )
  );
}

export default MachinesSpeedGraph;
