// import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  NavbarBrand,
  Nav,
  Container,
  Media,
  NavItem,
  Button,
  // Row,
  // Col,
} from "reactstrap";
import logo from "../../assets/img/brand/MiAssist.png";
import AuthService from "../../services/auth.service";
// import Badge from "@material-ui/core/Badge";
// import SettingsIcon from '@material-ui/icons/Settings';
import logoutWhite from "../../assets/img/brand/logoutWhite.png";
import Tooltip from "@material-ui/core/Tooltip";
import logoDropdown from "../../assets/img/brand/logoMA.png";

import { useState } from "react";

const AdminNavbar = ({
  clientName,
  setEIndexName,
  setClientName,
  setClientId,
}) => {
  const history = useHistory();
  const user = AuthService.getCurrentUser();
  const location = useLocation();
  const [logoutImage, setLogoutImage] = useState(logoutWhite);

  const logOut = () => {
    AuthService.logout();
    setClientName("");
    setClientId("");
    history.push("/auth/login");
  };

  console.log("User: ", user);

  return (
    <>
      <Navbar>
        <Container fluid>
          <NavbarBrand style={{ width: "15%" }}>
            <img
              alt="miwiegh-logo"
              className="navbar-brand-img img-fluid"
              src={logo}
              // style={{ maxWidth: '20%' }}
              // width={250}
            />
          </NavbarBrand>
          <NavItem tag="div" className="h1 text-white">
            {clientName}
          </NavItem>
          <NavItem tag="div">
            {/* <NavbarBrand tag="span">
              <Tooltip title="Back to Main Page" arrow>
                <Button className="m-0 p-0 bg-transparent border-0">
                  <Media className="mr-3 d-none d-lg-inline">
                    <span
                      className="mb-0 h1 font-weight-bold"
                      style={{ color: "#0ca6f2", textTransform: "capitalize" }}
                      onClick={() => {
                        if (user.role === "admin") {
                          setClientName("");
                          setClientId("");
                        }
                        history.push("/admin/index");
                      }}
                    >
                      {user.firstName}
                    </span>
                  </Media>
                </Button>
              </Tooltip>
            </NavbarBrand> */}
            <NavbarBrand tag="span">
              <Media className="btn d-none d-lg-inline px-0">
                <span
                  className="h1 font-weight-bold"
                  style={{ color: "#0ca6f2", textTransform: "capitalize" }}
                >
                  {user.firstName}
                </span>
              </Media>
              <Tooltip title="Back to Main Page" arrow>
                <span
                  className="btn pr-0"
                  onClick={() => {
                    setClientName("");
                    setClientId("");
                    history.push("/admin/index");
                  }}
                >
                  <img alt="..." width={80} src={logoDropdown} />
                </span>
              </Tooltip>
            </NavbarBrand>
            <NavbarBrand>
              <Tooltip title="Logout" arrow>
                <Button
                  className="bg-transparent border-0 p-0 ml-4"
                  onClick={() => logOut()}
                  onMouseEnter={() => setLogoutImage(logoutWhite)}
                  onMouseLeave={() => setLogoutImage(logoutWhite)}
                >
                  <img
                    alt="miwiegh-logo"
                    className="navbar-brand-img"
                    src={logoutImage}
                    width={50}
                  />
                </Button>
              </Tooltip>
            </NavbarBrand>
          </NavItem>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
