import React, { useState, useEffect } from "react";
import { Col, FormGroup, Input } from "reactstrap";

function CustomSelectComponent({ item, idx, value, onChange, disabled }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    item
      .options()
      .then((res) => {
        setData(res.data[Object.keys(res.data)[0]]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [item]);

  return (
    <Col lg="3" key={idx}>
      <FormGroup className="mb-2">
        <label className="form-control-label textWhite d-block">
          {item.label}
        </label>
        <Input
          placeholder={item.placeholder}
          name={item.name}
          type="select"
          value={value}
          onChange={onChange}
          disabled={disabled}
          className={disabled ? 'textBg-disabled' : "textBg"}

        >
          <option />
          {data &&
            data.map((option, idx) => {
              return (
                <option key={idx} value={option.id}>
                  {option.name || option.label}
                </option>
              );
            })}
        </Input>
      </FormGroup>
    </Col>
  );
}

export default CustomSelectComponent;
