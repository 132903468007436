import Register from "./views/AuthViews/Register.js";
import Login from "./views/AuthViews/Login.js";
import ClientSelectionPage from "views/ClientSelectionPage/index";
import Index from "views/index.js";
import ManagementPanel from "views/managementPanel/index.js";
import ProductionData from "views/DataPages/ProductionData/ProductionData.jsx";
import ResetPassword from "views/AuthViews/ResetPassword.js";

var routes = [
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: ClientSelectionPage,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/panel",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: ManagementPanel,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/productionData/:clientId/:machineId",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: ProductionData,
    layout: "/admin",
    group: "All",
  },
  {
    path: "/reset-password/:newToken/:userStatus?",
    name: "ResetPassword",
    icon: "ni ni-circle-08 text-pink",
    component: ResetPassword,
    layout: "/auth",
  },
];
export default routes;
