import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function TermsModal({ isOpen, toggle }) {
  return (
    <Modal
      centered
      scrollable
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      contentClassName="modal-bg"
      backdropClassName="modal-backdrop-opacity"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <button className="close" onClick={toggle}>
            <div className="text-white rounded-circle">
              <i className="fas fa-times" />
            </div>
          </button>
        }
      >
        <h2 style={{ color: "#a7ce39" }}>Terms and Conditions</h2>
      </ModalHeader>
      <ModalBody>
        <div className="terms-and-conditions text-muted">
          <h4 className="text-muted">
            Please read these Terms and Conditions carefully before using the
            MiAssist Dashboard (the "Service") operated by MiAssist, Inc.
            ("MiAssist," "we," "us," or "our"). By accessing or using the
            Service, you agree to be bound by these Terms and Conditions. If you
            do not agree with any of these terms, please do not use the Service.
          </h4>

          <ol className="terms-list">
            <li>
              <h5 className="text-muted">Acceptance of Terms</h5>
              <p>
                By accessing and using the Service, you acknowledge that you
                have read, understood, and agree to abide by these Terms and
                Conditions, as well as our Privacy Policy. These Terms
                constitute a legal agreement between you and MiAssist.
              </p>
            </li>
            <li>
              <h5 className="text-muted">Use of the Service</h5>
              <p>
                <strong>(a)</strong> You must be at least 18 years old and have
                the legal authority to enter into these Terms and Conditions to
                use the Service. By accessing or using the Service, you
                represent and warrant that you meet these requirements.
              </p>
              <p>
                <strong>(b)</strong> You are responsible for maintaining the
                confidentiality of your account credentials and for all
                activities that occur under your account.
              </p>
              <p>
                <strong>(c)</strong> You agree not to use the Service for any
                illegal, unauthorized, or unethical purposes, including but not
                limited to engaging in activities that violate applicable laws
                and regulations.
              </p>
            </li>
            <li>
              <h5 className="text-muted">Data and Privacy</h5>
              <p>
                <strong>(a)</strong> By using the Service, you acknowledge and
                consent to the collection, storage, and use of your personal
                information as outlined in our Privacy Policy.
              </p>
              <p>
                <strong>(b)</strong> You agree not to share your account
                credentials or access to the Service with third parties without
                MiAssist's explicit permission.
              </p>
            </li>
            <li>
              <h5 className="text-muted">Intellectual Property</h5>
              <p>
                <strong>(a)</strong> The content, features, and functionality of
                the Service are owned by MiAssist and protected by international
                copyright, trademark, and other intellectual property laws.
              </p>
              <p>
                <strong>(b)</strong> You may not reproduce, modify, distribute,
                display, perform, or otherwise use any content from the Service
                without MiAssist's prior written consent.
              </p>
            </li>
            <li>
              <h5 className="text-muted">Disclaimer of Warranties</h5>
              <p>
                The Service is provided "as is" and "as available." MiAssist
                makes no warranties, express or implied, regarding the accuracy,
                reliability, or suitability of the Service for any purpose. You
                agree to use the Service at your own risk.
              </p>
            </li>
            <li>
              <h5 className="text-muted">Limitation of Liability</h5>
              <p>
                <strong>(a)</strong> To the fullest extent permitted by law,
                MiAssist shall not be liable for any direct, indirect,
                incidental, special, consequential, or punitive damages arising
                out of or related to the use of the Service.
              </p>
              <p>
                <strong>(b)</strong> In no event shall MiAssist's total liability
                to you exceed the amount paid by you, if any, for the use of the
                Service during the past 12 months.
              </p>
            </li>
            <li>
              <h5 className="text-muted">Changes to Terms</h5>
              <p>
                MiAssist reserves the right to modify, amend, or update these
                Terms and Conditions at any time without prior notice. Continued
                use of the Service after any such changes constitutes your
                acceptance of the new Terms.
              </p>
            </li>
            <li>
              <h5 className="text-muted">Termination</h5>
              <p>
                MiAssist reserves the right to terminate or suspend your account
                and access to the Service at any time, for any reason, without
                prior notice.
              </p>
            </li>
            <li>
              <h5 className="text-muted">Governing Law</h5>
              <p>
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of United Kingdom, without regard to
                its conflict of law principles.
              </p>
            </li>
          </ol>

          <p>
            By accessing or using the Service, you agree to these Terms and
            Conditions.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Agree</Button>
      </ModalFooter>
    </Modal>
  );
}

export default TermsModal;
