import React, { useState, useEffect } from "react";
import { Col, FormGroup, Row, Spinner } from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import DateRangeSelector from "views/shared/DateRangeSelector";
import Select from "react-select";

const customStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base, state) => ({
    ...base,
    // background: "#023950",
    // background: "#737977",
    background: "#19312e",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    borderColor: state.isFocused ? "#0ca6f2" : "#8898aa",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#0ca6f2" : "#0ca6f2",
    },
  }),
  menu: (base) => ({
    ...base,
    // background: "#737977",
    background: "#19312e",
    color: "white",
    // override border radius to match the box
    border: "1px solid #8898aa",
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    "::-webkit-scrollbar": {
      display: "none",
    },
    // overflow: 'hidden'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isFocused ? "#2462ad" : null,
      backgroundColor: isFocused ? "#0ca6f2" : null,
      color: isFocused ? "black" : "white",
      // color: "#333333",
    };
  },
};

function TableAuditTrailData({ clientId, machineId, title }) {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 7);
  const [filtersData, setFiltersData] = useState({
    start_date: yesterday.toISOString(),
    end_date: today.toISOString(),
    description: "All",
    group: "All",
  });
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [descriptionList, setDescriptionList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const handleDateRange = (start_date, end_date) => {
    setFiltersData((prevState) => {
      return {
        ...prevState,
        start_date,
        end_date,
      };
    });
  };

  const handleFilters = (name, value) => {
    setPage(0);
    setFiltersData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const fetchTableData = async (
    page = 0,
    rowsPerPage = 10,
    clientId,
    machineId,
    User_Group = "All",
    description = "All"
  ) => {
    setTableData("Loading...");
    axios
      .get(`${baseUrl}/api/audit_trail_data/${page}/${rowsPerPage}`, {
        params: {
          ...filtersData,
          clientId,
          machineId,
          User_Group: User_Group,
          description,
        },
        headers: authHeader(),
      })
      .then((res) => {
        setTableData(res.data.auditData);
        setCount(res.data.count);
      })
      .catch((err) => {
        setTableData([]);
      });
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(`${baseUrl}/api/audit_trail_data_groups`, {
          params: { ...filtersData, clientId, machineId },
          headers: authHeader(),
        });
        let groups = res.data.userGroups.map((item) => {
          return { value: item, label: item };
        });
        setGroupList([{ value: "All", label: "All" }, ...groups]);
      } catch (error) {
        setGroupList([{ value: "All", label: "All" }]);
      }
    })();

    (async () => {
      try {
        const res = await axios.get(
          `${baseUrl}/api/audit_trail_data_descriptions`,
          {
            params: { ...filtersData, clientId, machineId },
            headers: authHeader(),
          }
        );
        let descriptions = res.data.descriptions.map((item) => {
          return { value: item, label: item };
        });
        setDescriptionList([{ value: "All", label: "All" }, ...descriptions]);
      } catch (error) {
        setDescriptionList([{ value: "All", label: "All" }]);
      }
    })();
  }, [machineId, clientId]);

  useEffect(() => {
    setTableData("Loading...");
    fetchTableData(
      page,
      rowsPerPage,
      clientId,
      machineId,
      filtersData.group,
      filtersData.description
    );
  }, [page, rowsPerPage, clientId, filtersData]);

  const tableColumns = [
    {
      id: "TIMESTAMP",
      title: "Date/Time",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Machine_ID",
      title: "Machine",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "User_Group",
      title: "User Group",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Description",
      title: "Description",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Value_Before",
      title: "Value Before",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },

    {
      id: "Value_After",
      title: "Value After",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
  ];

  const handleChangePage = (event, newPage) => {
    console.log("NEW PAGE => ", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <span className="mb-4">
      <h2 className="px-4 pt-4" style={{ color: "#0ca6f2" }}>
        {title}
      </h2>
      <Row className="mb-2">
        <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
          <DateRangeSelector
            filtersData={filtersData}
            handleDateRange={handleDateRange}
          />
        </Col>
        <Col xs={12} sm={12} md={3} lg={4} xl={4} xxl={2}>
          <FormGroup>
            <label className="form-control-label textWhite d-block">
              Select Group:
            </label>
            <Select
              placeholder="Select Group..."
              name="currentGroup"
              value={{ value: filtersData.group, label: filtersData.group }}
              onChange={(e) => handleFilters("group", e.value)}
              styles={customStyles}
              options={groupList}
            />
          </FormGroup>
        </Col>
        <Col xs={12} sm={12} md={3} lg={4} xl={4} xxl={2}>
          <FormGroup>
            <label className="form-control-label textWhite d-block">
              Select Description:
            </label>
            <Select
              placeholder="Select Description..."
              name="description"
              value={{
                value: filtersData.description,
                label: filtersData.description,
              }}
              onChange={(e) => handleFilters("description", e.value)}
              styles={customStyles}
              options={descriptionList}
            />
          </FormGroup>
        </Col>
      </Row>
      {tableData === "Loading..." ? (
        <div className="row justify-content-center my-5">
          <Spinner>Loading...</Spinner>
        </div>
      ) : tableData.length > 0 ? (
        <>
          <Table
            values={tableData}
            columns={tableColumns}
            tableClasses={{
              table:
                "table align-items-center table-flush table-sm border-bottom text-white ",
              thead: "",
              tbody: "list table-td-color",
            }}
          />
          <TablePagination
            component="div"
            className="text-muted"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />{" "}
        </>
      ) : (
        <span className="m-4">No Data Found</span>
      )}
    </span>
  );
}

export default TableAuditTrailData;
