import { Card, CardBody, Row, Col } from "reactstrap";
import RecipesPieChart from "./Charts/RecipesPieChart";
import ProductsPieChart from "./Charts/ProductsPieChart";
import TimeDistributionStatusPieChart from "./Charts/TimeDistributionStatusPieChart";
import SpeedGraph from "./Charts/SpeedGraph";
import AlarmGroupsPieChart from "./Charts/AlarmGroupsPieChart";

const ChartContainer = ({
  filtersData,
  setFiltersData,
  clientId,
  machineId,
}) => {
  console.log("Chart Rendered!");
  return (
    <>
      <div className="header bg-transparent py-0 mt-4 m-3">
        <div className="header-body">
          <Row>
            <Col
              xs="12"
              md="4"
              // xl={window.innerWidth > 1880 ? "3" : "4"}
              className="mb-4"
              style={{ minHeight: "600px" }}
            >
              <TimeDistributionStatusPieChart
                filtersData={filtersData}
                setFiltersData={setFiltersData}
                clientId={clientId}
                machineId={machineId}
              />
            </Col>
            <Col
              xs="12"
              md="4"
              // xl={window.innerWidth > 1880 ? "6" : "8"}
              className="mb-4"
              style={{ minHeight: "600px" }}
            >
              {/* <RecipesPieChart */}
              <AlarmGroupsPieChart
                filtersData={filtersData}
                setFiltersData={setFiltersData}
                clientId={clientId}
                machineId={machineId}
              />
            </Col>
            <Col
              xs="12"
              md="4"
              // xl={window.innerWidth > 1880 ? "3" : "4"}
              className="mb-4"
              style={{ minHeight: "600px" }}
            >
              <ProductsPieChart
                filtersData={filtersData}
                setFiltersData={setFiltersData}
                clientId={clientId}
                machineId={machineId}
              />
            </Col>
            <Col xs="12" className="mb-4">
              <Card className="card-stats" style={{ height: "100%" }}>
                <CardBody className="p-0">
                  <SpeedGraph
                    title={"Speed/Min"}
                    filtersData={filtersData}
                    setFiltersData={setFiltersData}
                    clientId={clientId}
                    machineId={machineId}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ChartContainer;
