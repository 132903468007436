import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import TermsModal from "./TermsModal";
// import axios from "axios";
// import { baseUrl } from "constants/url";
// import authHeader from "services/auth-header";
// import VideoPlayerModal from "views/Tutorials/VideoPlayerModal";

const ResetPassword = () => {
  const history = useHistory();
  const params = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [termsConditionsCheckbox, setTermsConditionsCheckbox] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  // const [openVideoPlayerModal, setOpenVideoPlayerModal] = useState(false);
  // const [videoPlayerModalData, setVideoPlayerModalData] = useState({});

  const handleSubmit = () => {
    if (params.userStatus === "create" && !termsConditionsCheckbox) {
      alert("Please check mark the Terms and Conditions!");
      return;
    }

    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    console.log("hello");
    AuthService.resetPassword(params.newToken, password)
      .then((res) => {
        setSuccessMessage("Your password has been reset!");
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMessage(
          err.message === "Network Error"
            ? "Server error!"
            : err.response.data.message
        );
        setIsLoading(false);
      });
  };

  // const handleNewUserTutorialModal = () => {
  //   //api to get login tutorial
  //   axios
  //     .get(`${baseUrl}/api/admin/tutorials/2`)
  //     .then((res) => {
  //       console.log(res);
  //       setOpenVideoPlayerModal(true);
  //       setVideoPlayerModalData(res.data.tutorials);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="h1 text-center mb-4" style={{ color: "#0ca6f2" }}>
              <div>Reset Password</div>
            </div>
            {errorMessage && (
              <div className="h4 text-center mb-4" style={{ color: "#ff0000" }}>
                <div>{errorMessage}</div>
              </div>
            )}
            {successMessage ? (
              <div className="h4 text-center mb-4" style={{ color: "#ffffff" }}>
                <div>{successMessage}</div>
              </div>
            ) : (
              <Form role="form">
                <FormGroup className="mb-1">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="textBg">
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      className="textBg"
                      // autoComplete="new-password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-1">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText className="textBg">
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirm Password"
                      type="password"
                      className="textBg"
                      // autoComplete="new-password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                {params.userStatus === "create" && (
                  <Row className="my-3">
                    <Col xs="12">
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          checked={termsConditionsCheckbox}
                          style={{ transform: "scale(1.5)" }}
                          onChange={() =>
                            setTermsConditionsCheckbox(!termsConditionsCheckbox)
                          }
                        />
                        <Label check className="ml-3">
                          <span className="text-muted pb-4">
                            I agree with the{" "}
                            <span
                              className="font-weight-bolder text-info"
                              style={{ cursor: "pointer" }}
                              onClick={() => setOpenTermsModal(true)}
                            >
                              Terms and Conditions
                            </span>
                          </span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <div className="text-center">
                  <Button
                    className="mt-4"
                    style={{ backgroundColor: "#0ca6f2", border: "none" }}
                    type="button"
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <>
                        <CircularProgress size={15} />
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <span
              className="h3"
              style={{ cursor: "pointer", color: "#0ca6f2" }}
              onClick={(e) => history.push("/auth/login")}
            >
              <p>
                <i className="ni ni-bold-left mr-2" />
                Back to Login
              </p>
            </span>
          </Col>
          {/* // Tutorial display none */}
          {/* <Col className="text-right d-none" xs="6">
            <span
              className="h3"
              style={{ cursor: "pointer", color: "#a7ce39" }}
              onClick={() => handleNewUserTutorialModal()}
            >
              <small>New User? Watch Tutorial</small>
            </span>
          </Col> */}
        </Row>
      </Col>
      <TermsModal
        isOpen={openTermsModal}
        toggle={() => setOpenTermsModal(!openTermsModal)}
      />
      {/* {openVideoPlayerModal && (
        <VideoPlayerModal
          isOpen={openVideoPlayerModal}
          toggle={() => setOpenVideoPlayerModal((prevState) => !prevState)}
          modalData={videoPlayerModalData}
        />
      )} */}
    </>
  );
};

export default ResetPassword;
