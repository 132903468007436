import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  Input,
  CardFooter,
  Button,
  Col,
} from "reactstrap";
import axios from "axios";
import Plot from "react-plotly.js";
import {
  baseUrl,
  last_week,
  last_month,
  last_year,
  last_8_hours,
  last_16_hours,
  last_24_hours
} from 'constants/url';
import authHeader from "services/auth-header";
import moment from "moment";
import AuditDataModal from "../../AuditData/AuditDataModal";
import AlarmInfoModal from "../../AlarmsInfo/AlarmInfoModal";

function SpeedGraph({ title, filtersData, clientId, machineId }) {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [duration, setDuration] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalAlarm, setOpenModalAlarm] = useState(false);

  const fetchData = (filterObject) => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/production_chart_data/speed_per_min`, {
        params: { ...filterObject, clientId, machineId },
        headers: authHeader(),
      })
      .then((res) => {
        const data = res.data.data;

        axios
          .get(`${baseUrl}/api/alarm_chart_data`, {
            params: { ...filterObject, clientId, machineId },
            headers: authHeader(),
          })
          .then((resAlarm) => {
            const alarmData = resAlarm.data.data;
            // Get unique entries of x for all
            const labels = [
              ...new Set([
                ...data.speed.x,
                ...alarmData.alarm.x,
                ...data.nextMachine.x,
              ]),
            ];

            // Initialize speed, alarm, and nextMachine arrays
            const speed = [];
            const alarm = [];
            const nextMachine = [];
            const group = [];

            labels.forEach((label) => {
              // Check if data exists in y for speed
              const speedIndex = data.speed.x.indexOf(label);
              if (speedIndex !== -1) {
                speed.push(data.speed.y[speedIndex]);
              } else {
                speed.push(0); // Add 0 if data doesn't exist
              }

              // Check if data exists in y for alarm
              const alarmIndex = alarmData.alarm.x.indexOf(label);
              if (alarmIndex !== -1) {
                alarm.push(alarmData.alarm.y[alarmIndex]);
                group.push(alarmData.alarm.group[alarmIndex]);
              } else {
                alarm.push(null); // Add 0 if data doesn't exist
                group.push("");
              }

              // Check if data exists in y for nextMachine
              const nextMachineIndex = data.nextMachine.x.indexOf(label);
              if (nextMachineIndex !== -1) {
                nextMachine.push(data.nextMachine.y[nextMachineIndex]);
              } else {
                nextMachine.push(null); // Add 0 if data doesn't exist
              }
            });

            setChartLabels(labels);
            setChartData({ speed, alarm, nextMachine, group });
            setIsLoading(false);
          })
          .catch((errAlarm) => {
            setIsLoading(false);
            setChartData([]);
            setChartLabels([]);
          });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setChartData([]);
        setChartLabels([]);
      });
  };

  const onChange = (event) => {
    const value = event.target.value;
    setDuration(value);
    if (value === "") {
      fetchData(filtersData);
    } else if (value === last_week) {
      const start = moment().subtract(1, "weeks").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    } else if (value === last_month) {
      const start = moment().subtract(1, "months").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    } else if (value === last_year) {
      const start = moment().subtract(1, "years").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    }
     else if (value === last_24_hours) {
      const start = moment().subtract(1, 'days').utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end
      });
    }
     else if (value === last_16_hours) {
       const start = moment().subtract(16, 'hours').utcOffset(0, true).format();
       const end = moment().utcOffset(0, true).format();
       fetchData({
         ...filtersData,
         start_date: start,
         end_date: end
       });
    }
     else if (value === last_8_hours) {
     const start = moment().subtract(8, 'hours').utcOffset(0, true).format();
     const end = moment().utcOffset(0, true).format();
     fetchData({
       ...filtersData,
       start_date: start,
       end_date: end
     });
    }
  };

  useEffect(() => {
    setDuration("");
    fetchData(filtersData);
  }, [filtersData]);

  // let colorArray = chartData && chartData.alarm && chartData.alarm.map(item => {
  //   if (item > 8) {
  //     return '#F31559'
  //   } else if (item > 6) {
  //     return "#FF52A2"
  //   } else if (item > 4) {
  //     return "#FFB07F"
  //   } else {
  //     return "#FFECAF"
  //   }
  // })

  let colorArray =
    chartData &&
    chartData.group &&
    chartData.group.map((item) => {
      if (item.includes("Safety")) {
        return "#d20000";
      } else if (item.includes("Infeed")) {
        return "#cea423";
      } else if (item.includes("Transfer")) {
        return "#ccce16";
      } else if (item.includes("Film")) {
        return "#db6f15";
      } else if (item.includes("Jaws")) {
        return "#9c0000";
      } else if (item.includes("Seal")) {
        return "#fdcf58";
      } else if (item.includes("Outfeed")) {
        return "#757676";
      } else if (item.includes("Temp")) {
        return "#f27d0c";
      } else if (item.includes("External")) {
        return "#800909";
      } else {
        return "#f07f13";
      }
    });

  // function getSymbols(yValues) {
  //   // Define a mapping from y values to symbols
  //   const symbolMap = {
  //     0: "triangle-down", // Example: If y is 0, use a circle symbol
  //     1: "triangle-up", // Example: If y is 1, use a square symbol
  //     // 0: 'M0,0 L0.5,1 L1,0 Z', // Example: If y is 0, use a circle symbol
  //     // 0.1: 'M0,1 L0.5,0 L1,1 Z', // Example: If y is 1, use a square symbol
  //     // Add more mappings as needed
  //   };

  //   // Create an array of symbols based on the y values
  //   const symbols = yValues.map((y) => symbolMap[y]);

  //   return symbols;
  // }

  function getSymbols(yValues) {
    // Define a mapping from y values to symbols
    const symbolMap = {
        0: "triangle-down", // Example: If y is 0, use a circle symbol
        // Using a conditional operator to select the symbol based on y value
        greaterThanZero: "triangle-up", // Example: If y is greater than 0, use a triangle-up symbol
        // Add more mappings as needed
    };

    // Create an array of symbols based on the y values
    const symbols = yValues.map((y) => {
        // Select the appropriate symbol based on the value of y
        return y > 0 ? symbolMap.greaterThanZero : symbolMap[0];
    });

    return symbols;
}

  // function getColors(yValues) {
  //   // Define a mapping from y values to symbols
  //   const colorMap = {
  //     0: "#7f7f00", // Example: If y is 0, use a circle symbol - red
  //     1: "#FFFF00", // Example: If y is 1, use a square symbol - green
  //     // Add more mappings as needed
  //   };

  //   // Create an array of symbols based on the y values
  //   const colors = yValues.map((y) => colorMap[y]);

  //   return colors;
  // }

  function getColors(yValues) {
    // Define a mapping from y values to colors
    const colorMap = {
        0: "#7f7f00", // Example: If y is 0, use a circle symbol - red
        // Using a conditional operator to select the color based on y value
        greaterThanZero: "#FFFF00", // Example: If y is greater than 0, use a different color
        // Add more mappings as needed
    };

    // Create an array of colors based on the y values
    const colors = yValues.map((y) => {
        // Select the appropriate color based on the value of y
        return y > 0 ? colorMap.greaterThanZero : colorMap[0];
    });

    return colors;
}

  // let symbolArray = chartData && chartData.nextMachine && chartData.nextMachine.map(item => {
  //   if (item === 0) {
  //     return 'circle'
  //   } else {
  //     return "triangle-up"
  //   }
  // })

  const speed = {
    type: "scatter",
    mode: "lines+points",
    x: chartLabels,
    y: chartData && chartData.speed,
    marker: { color: "white" },
    name: "Speed/Min",
  };

  const alarm = {
    type: "scatter",
    mode: "markers",
    x: chartLabels,
    y: chartData && chartData.alarm,
    yaxis: "y2",
    // customdata: chartData && chartData.alarmDescription,
    hovertemplate: "<b>%{text}</b>",
    text: chartData && chartData.group,
    // text: ['a','b','c','d'],
    // hovertemplate: '%{customdata}',
    marker: { color: colorArray, size: 10, symbol: "cross" },
    name: "Alarm",
  };

  const nextMachine = {
    type: "scatter",
    mode: "markers",
    x: chartLabels,
    y: chartData && chartData.nextMachine,
    // marker: { color: "blue", size: 7, symbol: "circle" },
    marker: {
      color:
        chartData && chartData.nextMachine && getColors(chartData.nextMachine),
      size: 13,
      symbol:
        chartData && chartData.nextMachine && getSymbols(chartData.nextMachine),
    },
    name: "Next Machine",
  };

  const formattedChartData = [speed, alarm, nextMachine];

  const options2 = {
    showlegend: true,
    autosize: true,
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title: "Speed Per Min" },
    yaxis2: {
      title: "Alarm",
      titlefont: { color: "red" },
      tickfont: { color: "red" },
      overlaying: "y",
      side: "right",
      range: [-1, 20]
    },
    xaxis: {
      // tickformat: labelFormat,
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h2 className="mb-0" style={{ color: "#0ca6f2" }}>
                {title}
              </h2>
            </div>
            <div className="col">
              <Nav className="justify-content-end" pills>
                <NavItem>
                  <Input
                    placeholder="Duration"
                    name="country_id"
                    type="select"
                    value={duration}
                    className="textBg"
                    bsSize="sm"
                    style={{ color: "#000000" }}
                    onChange={onChange}
                  >
                    {["",last_week,
                      last_month,
                      last_year,
                      last_8_hours,
                      last_16_hours,
                      last_24_hours].map(
                      (option, idx) => {
                        return (
                          <option key={idx} value={option}>
                            {option}
                          </option>
                        );
                      }
                    )}
                  </Input>
                </NavItem>
              </Nav>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div className="m-5">Loading...</div>
          ) : Object.keys(chartData).length > 0 ? (
            <div className="chart" style={{ height: "auto" }}>
              <div className="chart-container-bar">
                {chartData && (
                  <Plot
                    data={formattedChartData}
                    className="w-100 h-100"
                    useResizeHandler
                    layout={options2}
                    config={config}
                    // onRelayout={(e) => {
                    //   if (e["xaxis.range[0]"] && e["xaxis.range[1]"]) {
                    //     setFiltersData({
                    //       ...filtersData,
                    //       start_date: new Date(e["xaxis.range[0]"]).toISOString(),
                    //       end_date: new Date(e["xaxis.range[1]"]).toISOString(),
                    //     });
                    //   }
                    // }}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="m-5">No Data Found</div>
          )}
        </CardBody>
        <CardFooter className="bg-transparent">
          <Row className="align-items-center justify-content-center">
            <Col xs={6} className="text-right">
              <Button
                className="w-50"
                onClick={() => setOpenModal(true)}
                // disabled={!Object.keys(chartData).length > 0}
                style={{ backgroundColor: "#0ca6f2", border: "none" }}
              >
                Audit Data
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                onClick={() => setOpenModalAlarm(true)}
                // disabled={!Object.keys(chartData).length > 0}
                className="w-50"
                color="secondary"
              >
                Alarms Info
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
      <AuditDataModal
        isOpen={openModal}
        toggle={() => setOpenModal((prevState) => !prevState)}
        clientId={clientId}
        machineId={machineId}
        filter={filtersData}
      />
      <AlarmInfoModal
        isOpen={openModalAlarm}
        toggle={() => setOpenModalAlarm((prevState) => !prevState)}
        clientId={clientId}
        machineId={machineId}
        filter={filtersData}
      />
    </>
  );
}

export default SpeedGraph;
