import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, FormGroup } from "reactstrap";
import DateRangeSelector from "./DateRangeSelector.jsx";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "services/auth-header";
import Select, { components } from "react-select";
import LineImage from "../../assets/img/icons/lines_icon.png";
import RecipeImage from "../../assets/img/icons/recipe_icon.png";

const customStyles = {
  input: (base) => ({
    ...base,
    color: "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "white",
  }),
  placeholder: (base) => ({
    ...base,
    color: "white",
  }),
  control: (base, state) => ({
    ...base,
    // background: "#023950",
    // background: "#737977",
    background: "#19312e",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    // borderColor: state.isFocused ? "yellow" : "green",
    borderColor: state.isFocused ? "#0ca6f2" : "#8898aa",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#0ca6f2" : "#0ca6f2",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2000,
    // background: "#737977",
    background: "#19312e",
    color: "white",
    // override border radius to match the box
    border: "1px solid #8898aa",
    // border: '1px solid #a7ce39',
    borderRadius: 5,
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    // "::-webkit-scrollbar": {
    //   display: "none",
    // },
    // overflow: 'hidden'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isFocused ? "#2462ad" : null,
      backgroundColor: isFocused ? "#0ca6f2" : null,
      color: isFocused ? "black" : "white",
      // fontWeight: 'bold'
      // color: "#333333",
    };
  },
};

const DropdownIndicator = (props, image) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={image} width={25} alt="" />
    </components.DropdownIndicator>
  );
};

function Filters({ handleApplyFilters, filtersData, clientId, machineId }) {
  const [filterState, setFilterState] = useState({ ...filtersData });
  // const [recipeList, setRecipeList] = useState("Loading...");
  const [productList, setProductList] = useState("Loading...");

   useEffect(() => {
     setFilterState({ ...filtersData });
   }, [filtersData]);

  useEffect(() => {
    // if (filterState.recipe !== undefined) {
    //   axios
    //     .get(
    //       `${baseUrl}/api/get_lookup_data/${clientId}/${machineId}/PROD/recipe`,
    //       {
    //         headers: authHeader(),
    //       }
    //     )
    //     .then((res) => {
    //       const option = res.data.data.map((item) => {
    //         return {
    //           label: item,
    //           value: item,
    //         };
    //       });
    //       option.unshift({ label: 'All', value: 'All' });
    //       setRecipeList(option);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
    if (filterState.product !== undefined) {
      axios
        .get(
          `${baseUrl}/api/get_lookup_data/${clientId}/${machineId}/PROD/product`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          console.log(res);
          const option = res.data.data.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
          option.unshift({ label: 'All', value: 'All' });
          setProductList(option);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const onChangeSelectValue = (value, name) => {
    setFilterState((prevState) => {
      handleApplyFilters({
        ...prevState,
        [name.name]: value.value,
      });
      return {
        ...prevState,
        [name.name]: value.value,
      };
    });
  };

  const handleDateRange = (start_date, end_date) => {
    setFilterState((prevState) => {
      return {
        ...prevState,
        start_date,
        end_date,
      };
    });
  };

  const handleApplyFromDateFilter = () => {
    handleApplyFilters(filterState);
  };

  return (
    <Form>
      <div className="">
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={2} className="mb-3">
            <DateRangeSelector
              filtersData={filtersData}
              handleDateRange={handleDateRange}
              handleApplyFromDateFilter={handleApplyFromDateFilter}
            />
          </Col>
          {filterState.product !== undefined && (
            <Col xs={12} sm={6} md={6} lg={3} xl={4} xxl={2}>
              <FormGroup className="mb-3">
                <label className="form-control-label textWhite d-block filter-label-font-size">
                  Product
                </label>
                {productList === "Loading..." ? (
                  productList
                ) : (
                  <Select
                    placeholder="Select Product..."
                    components={{
                      DropdownIndicator: (props) =>
                        DropdownIndicator(props, RecipeImage),
                    }}
                    name="product"
                    value={{ label: filterState.product }}
                    onChange={onChangeSelectValue}
                    styles={customStyles}
                    options={productList}
                  />
                )}
              </FormGroup>
            </Col>
          )}

          {filterState.product !== undefined && (
              <Col
                className="justify-content-end align-items-center d-flex pt-4 mb-3"
                xs={12}
              >
                <Button
                  style={{ backgroundColor: "#0ca6f2", border: "none" }}
                  onClick={() => handleApplyFilters(filterState)}
                >
                  Apply
                </Button>
                <Button
                  className=""
                  color="secondary"
                  onClick={() => {
                    const today = new Date();
                    const yesterday = new Date(today);
                    yesterday.setDate(yesterday.getDate() - 7);
                    setFilterState({
                      start_date: yesterday.toISOString(),
                      end_date: today.toISOString(),
                      product: "All",
                      recipe: "All",
                    });
                    handleApplyFilters({
                      start_date: yesterday.toISOString(),
                      end_date: today.toISOString(),
                      product: "All",
                      recipe: "All",
                    });
                  }}
                >
                  Clear
                </Button>
              </Col>
            )}
        </Row>
      </div>
    </Form>
  );
}

export default Filters;
