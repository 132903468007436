import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import routes from "routes.js";
// import Particles from "react-tsparticles";
// import { particleOptions } from "constants/particleOptions";
import { useHistory } from "react-router-dom";
import AuthService from "../services/auth.service";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Admin = (props) => {
  const history = useHistory();
  const user = AuthService.getCurrentUser();
  const location = useLocation();
  const [clientName, setClientName] = useState(user?.clientName ? user.clientName : '')
  // const [eIndexName, setEIndexName] = useState(user?.prodIndex ? user.prodIndex : '')
  const [clientId, setClientId] = useState('')


  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      // const originalRequest = error.config;
      // if (error.response.status === 403 && !originalRequest._retry) {
      //   originalRequest._retry = true;
      //   const access_token = await refreshAccessToken();
      //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      //   return axios(originalRequest);
      // }
      console.log(error.response);
      if (error.response.status === 401) {
        AuthService.logout();
        history.push("/auth/login");
      }
      return Promise.reject(error);
    }
  );

  if (!user) {
    history.push("/auth/login");
  }

  React.useEffect(() => {
    if (user) {
      return;
    } else {
      history.push("/auth/login");
    }
  }, [user, history]);

  React.useEffect(() => {
    if (location.pathname.includes('/admin/index')) {
      setClientName(user?.clientName ? user.clientName : '')
      // setEIndexName(user?.prodIndex ? user.prodIndex : '')
      setClientId(user?.role === 'admin' ? "" : user?.clientId)
    }
  }, [location.pathname])
  React.useEffect(() => {
    if (location.pathname.includes('/admin/dashboard')) {
      setClientName(user?.clientName ? user.clientName : '')
      setClientId(user?.role === 'admin' ? "" : user?.clientId)
    }
  }, [location.pathname])

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            // component={prop.component}
            component={() => <prop.component setClientName={setClientName} clientId={clientId} setClientId={setClientId} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    user && (
      <>
        {/* <Particles id="tsparticles" options={particleOptions} /> */}
        <div
          className="main-content min-vh-100 pb-7"
          style={{ backgroundColor: "#19312e" }}
        >
          <AdminNavbar {...props} clientName={clientName} setClientName={setClientName} clientId={clientId} setClientId={setClientId} />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
          <Container fluid className="position-absolute bottom-0">
            <AdminFooter />
          </Container>
        </div>
      </>
    )
  );
};

export default Admin;
