import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import DistributorsTable from "./distributorsTable";
import ClientsTable from "./clientsTable";
import MachinesTable from "./machinesTable";
import MachineTypesTable from "./machineTypesTable";
import UsersTable from "./usersTable";
import AuthService from "./../../services/auth.service";
import { useHistory } from "react-router-dom";
import { baseUrl } from "constants/url";
import axios from "axios";
import authHeader from "services/auth-header";

function Index() {
  const user = AuthService.getCurrentUser();

  const history = useHistory();

  const [usersData, setUsersData] = useState([]);
  const [usersCount, setUsersCount] = useState(0);

  const fetchUsersData = useCallback(() => {
    axios
      .get(`${baseUrl}/api/admin/user`, {
        headers: authHeader(),
      })
      .then((res) => {
        setUsersData(res.data.users);
        setUsersCount(res.data.users.length);
      })
      .catch((err) => {
        console.log(err);
        setUsersCount([]);
      });
  }, []);

  const [clientsData, setClientsData] = useState([]);
  const [clientsCount, setClientsCount] = useState(0);

  const fetchClientsData = useCallback(() => {
    axios
      .get(`${baseUrl}/api/admin/client`, {
        headers: authHeader(),
      })
      .then((res) => {
        setClientsData(res.data.clients);
        setClientsCount(res.data.clients.length);
      })
      .catch((err) => {
        console.log(err);
        setClientsData([]);
      });
  }, []);

  const [machinesData, setMachinesData] = useState([]);
  const [machinesCount, setMachinesCount] = useState(0);

  const fetchMachinesData = useCallback(() => {
    axios
      .get(`${baseUrl}/api/admin/machine`, {
        headers: authHeader(),
      })
      .then((res) => {
        setMachinesData(res.data.machines);
        setMachinesCount(res.data.machines.length);
      })
      .catch((err) => {
        console.log(err);
        setMachinesData([]);
      });
  }, []);

  if (user.role === "user") {
    history.push("/admin/index");
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      className="px-5"
    >
      {user.role === "admin" && (
        <>
          <Grid item className="my-2" xs={12}>
            <DistributorsTable />
          </Grid>
          <Grid item className="my-2" xs={12} lg={6}>
            <MachinesTable
              tableData={machinesData}
              setTableData={setMachinesData}
              count={machinesCount}
              fetchData={fetchMachinesData}
            />
          </Grid>
          <Grid item className="my-2" xs={12} lg={6}>
            <MachineTypesTable />
          </Grid>
          <Grid item className="my-2" xs={12}>
            <ClientsTable
              tableData={clientsData}
              setTableData={setClientsData}
              count={clientsCount}
              fetchData={fetchClientsData}
              fetchUsersData={fetchUsersData}
              fetchMachinesData={fetchMachinesData}
            />
          </Grid>


        </>
      )}

      <Grid item className="my-2" xs={12}>
        <UsersTable
          tableData={usersData}
          setTableData={setUsersData}
          count={usersCount}
          fetchData={fetchUsersData}
        />
      </Grid>
    </Grid>
  );
}

export default Index;
