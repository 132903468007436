import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  Input,
} from "reactstrap";
import axios from "axios";
import {
  baseUrl,
  last_week,
  last_month,
  last_year,
  last_8_hours,
  last_16_hours,
  last_24_hours
} from 'constants/url';
import Plot from "react-plotly.js";
import authHeader from "services/auth-header";
import moment from "moment";

export default function TimeDistributionStatusPieChart({
  filtersData,
  clientId,
  machineId,
}) {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [duration, setDuration] = useState("");

  const fetchData = (filterObject) => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/production_chart_data/idle_run_alarm`, {
        params: { ...filterObject, clientId, machineId },
        headers: authHeader(),
      })
      .then((res) => {
        let labels = [];
        let data = [];
        res.data.data.pieChunks.forEach((item) => {
          labels.push(item.x);
          data.push(item.y);
        });
        setChartLabels(labels);
        setChartData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setChartLabels([]);
        setChartData([]);
      });
  };

  const onChange = (event) => {
    const value = event.target.value;
    setDuration(value);
    if (value === "") {
      fetchData(filtersData);
    } else if (value === last_week) {
      const start = moment().subtract(1, "weeks").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    } else if (value === last_month) {
      const start = moment().subtract(1, "months").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    } else if (value === last_year) {
      const start = moment().subtract(1, "years").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end,
      });
    }
     else if (value === last_24_hours) {
      const start = moment().subtract(1, 'days').utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      fetchData({
        ...filtersData,
        start_date: start,
        end_date: end
      });
    }
     else if (value === last_16_hours) {
       const start = moment().subtract(16, 'hours').utcOffset(0, true).format();
       const end = moment().utcOffset(0, true).format();
       fetchData({
         ...filtersData,
         start_date: start,
         end_date: end
       });
    }
     else if (value === last_8_hours) {
     const start = moment().subtract(8, 'hours').utcOffset(0, true).format();
     const end = moment().utcOffset(0, true).format();
     fetchData({
       ...filtersData,
       start_date: start,
       end_date: end
     });
    }
  };

  useEffect(() => {
    setDuration("");
    fetchData(filtersData);
  }, [filtersData]);

  const productsData = {
    labels: chartLabels && chartLabels,
    values: chartData && chartData,
    type: "pie",
    hoverinfo: "label+percent+data",
    hole: 0.4,
  };

  const formattedChartData = [productsData];

  const options2 = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    legend: {
      orientation: "h",
      yanchor: "top",
      // y: 0.1,
      xanchor: "top",
      // x: 0.1,
    },
    annotations: {
      font: {
        size: 20,
      },
      showarrow: false,
      // text: 'GHG',
      x: 0.17,
      y: 0.5,
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true,
  };

  return (
    <Card className="h-100">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0" style={{ color: "#0ca6f2" }}>
              Machine Status
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <Input
                  placeholder="Duration"
                  name="duration"
                  type="select"
                  value={duration}
                  className="textBg"
                  bsSize="sm"
                  style={{ color: "#000000" }}
                  onChange={onChange}
                >
                  {["",last_week,
                      last_month,
                      last_year,
                      last_8_hours,
                      last_16_hours,
                      last_24_hours].map(
                    (option, idx) => {
                      console.log('option', option)
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    }
                  )}
                </Input>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-0 h-100">
        {isLoading ? (
          <div className="m-5">Loading...</div>
        ) : chartData.length > 0 ? (
          <div className="chart h-100">
            <div
              className="chart-container"
              style={{
                // position: "relative",
                margin: "auto",
                height: "100%",
              }}
            >
              <Plot
                data={formattedChartData}
                className="w-100 h-100"
                useResizeHandler
                layout={options2}
                config={config}
              />
            </div>
          </div>
        ) : (
          <div className="m-5">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}
