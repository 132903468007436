import React from "react";
import { Modal, ModalBody } from "reactstrap";
import TableAlarmInfo from "./TableAlarmInfo";

function AlarmInfoModal({ isOpen, toggle, clientId, machineId, filter }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdropClassName="modal-backdrop-opacity"
      contentClassName="modal-bg px-4"
      className="modal-width"
    >
      <ModalBody className="p-3">
        <TableAlarmInfo
          filter={filter}
          clientId={clientId}
          title={"Alarm Info"}
          machineId={machineId}
        />
      </ModalBody>
    </Modal>
  );
}

export default AlarmInfoModal;
