import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from "./App";


ReactDOM.render(
  <React.Fragment>
    <ToastContainer
      position="top-right"
      autoClose={4000}
      hideProgressBar={true}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </React.Fragment>,
  document.getElementById("root")
);
